var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticStyle: { "margin-top": "5px" } }, [
        _c(
          "div",
          {
            staticClass: "button-click-left",
            staticStyle: { margin: "0 10px 20px 0" },
          },
          [
            _c(
              "el-select",
              {
                staticClass: "button-left",
                attrs: { placeholder: "Trạng thái" },
                on: {
                  change: function ($event) {
                    return _vm.searchByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.statusEmployeee,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "statusEmployeee", $$v)
                  },
                  expression: "dataSearch.statusEmployeee",
                },
              },
              _vm._l(_vm.statusEmployeeList, function (item) {
                return _c("el-option", {
                  key: item,
                  attrs: { label: item, value: item },
                })
              }),
              1
            ),
            _c(
              "el-select",
              {
                staticClass: "button-left",
                attrs: { placeholder: "Phòng ban", clearable: "" },
                on: {
                  change: function ($event) {
                    return _vm.searchByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.idDepartment,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "idDepartment", $$v)
                  },
                  expression: "dataSearch.idDepartment",
                },
              },
              _vm._l(_vm.departmentList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.departmentName, value: item.id },
                })
              }),
              1
            ),
            _c(
              "el-input",
              {
                staticStyle: { width: "280px" },
                attrs: {
                  placeholder: "Nhập tên hoặc số điện thoại",
                  clearable: "",
                },
                on: {
                  clear: function ($event) {
                    return _vm.searchByProperties()
                  },
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.employeeNameOrPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "employeeNameOrPhone", $$v)
                  },
                  expression: "dataSearch.employeeNameOrPhone",
                },
              },
              [
                _c("el-button", {
                  attrs: { slot: "append", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.searchByProperties()
                    },
                  },
                  slot: "append",
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingData,
                  expression: "loadingData",
                },
              ],
              ref: "tableDetail",
              attrs: {
                "empty-text": _vm.textTable,
                "element-loading-text": _vm.$tableLoading,
                "element-loading-spinner": "el-icon-loading",
                "element-loading-background": "rgba(255,255,255, 0)",
                data: _vm.employeeSalaryApplyList,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "50", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fullName",
                  "min-width": "100",
                  label: "Tên nhân viên",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-span", [_vm._v(_vm._s(scope.row.fullName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Số điện thoại",
                  "min-width": "170",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("el-span", [_vm._v(_vm._s(scope.row.phone))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "200", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.handleConfigEmployee(scope.row)
                              },
                            },
                          },
                          [_vm._v("Thiết lập")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticStyle: { float: "right", margin: "15px 0" } }),
      _c("update-config-attendance-employee", {
        ref: "UpdateConfigAttendanceEmployee",
        attrs: { dialogVisible: _vm.showUpdateDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogClose()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }